import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclick", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
        });

    }
    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }



    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        const search = window.location.href;
        var test = search.split("aff=")[1]
        // console.log(test);
        if (test != undefined) {
            this.saveclick(test)
        }
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    System: res.data.tblcatagoryx[0].System
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/tabgamelistpop", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        slotlist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "10",
            System: Systems,
            playerid: Username

        })
            .then((res) => {
                if (res.data.status === 200) {

                    // this.state.recentmenu
                    this.setState({
                        recentlist: res.data.message,
                        recentmenu: true
                    });
                    //////console.log(555, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await this.checklogin();
    }
    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        //  console.log("img", origin.length )
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    render() {
        return (
            <section className="x-index-top-container" data-animatable="fadeInModal">
                <div className="-index-inner-wrapper" style={{ backgroundColor: "black" }}>
                    <h1 className="-heading-title">Deepluss Casino คาสิโนออนไลน์ บาคาร่า ไฮโล เสือมังกร เว็บตรง Deepluss
                    </h1>
                    <Listmenu />
                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-animatable="fadeInUp" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                        Deepluss.BET คาสิโนออนไลน์ สล็อตออนไลน์ ที่ให้คุณสนุกได้ทุกรูปแบบ
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" data-animatable="fadeInModal">
                        <NavMenu />
                        <div className="-games-index-page">
                            <div className="x-special-menu-entry-wrapper js-special-menu-entry-wrapper">
                                <div className="container">
                                    <div className="x-special-menu-entry -main-container">
                                        <ul className="nav -nav">
                                            <li className="nav-item">
                                                {this.state.loginstate ? <button
                                                    className="nav-link -menu-bind-social animated fadeInUp"
                                                    data-toggle="modal"
                                                    data-target="#socialsharemodal1"
                                                    data-dismiss="modal"
                                                    data-animatable="fadeInUp"
                                                    data-delay={100}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-social-share.png?v=1"
                                                    />
                                                </button> : <button
                                                    className="nav-link -menu-bind-social animated fadeInUp"
                                                    data-toggle="modal"
                                                    data-target="#loginModal"
                                                    data-dismiss="modal"
                                                    data-animatable="fadeInUp"
                                                    data-delay={100}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-social-share.png?v=1"
                                                    />
                                                </button>}


                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href="/lucky-fortune"
                                                    className="nav-link -menu-wheel animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={150}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-wheel.png?v=1"
                                                    />
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className="nav-link -menu-bind-social animated fadeInUp"
                                                    data-toggle="modal"
                                                    data-target="#loginModal"
                                                    data-dismiss="modal"
                                                    data-animatable="fadeInUp"
                                                    data-delay={100}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-bind-social.png?v=1"
                                                    />
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href="/Affiliate"
                                                    className="nav-link -menu-invitation animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={150}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-invitation.png?v=1"
                                                    />
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href="/promotions"
                                                    className="nav-link -menu-birth-date animated fadeInUp"
                                                    target="_blank"
                                                    rel="nofollow noopener"
                                                    data-animatable="fadeInUp"
                                                    data-delay={200}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-happy-birth-day.png?v=1"
                                                    />
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                        <a
                                             href="/promotions"
                                            className="nav-link -menu-ranking animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={250}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-wm/img/special-menu-entry-ranking.png?v=1"
                                            />
                                        </a>
                                    </li> */}
                                            <li className="nav-item">
                                                <a
                                                    href="#"
                                                    className="nav-link -menu-movie animated fadeInUp"
                                                    target="_blank"
                                                    rel="nofollow noopener"
                                                    data-animatable="fadeInUp"
                                                    data-delay={300}
                                                >
                                                    <img
                                                        alt="Website name Image"
                                                        className="-bg"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-item-bg.png?v=1"
                                                    />
                                                    <img
                                                        alt="Website name Image"
                                                        className="-text-img img-fluid"
                                                        src="/build/web/igame-index-lobby-wm/img/special-menu-entry-movie.png?v=1"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h2 className="-heading-sub-title">Deepluss Casino คาสิโนออนไลน์เต็มรูปแบบ
                                ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container">
                                <div className="-games-list-container container">
                                    <div className="x-hot-games-container">
                                        {(this.state.recentmenu) ?
                                            <div>
                                                <div className="-inner-heading-wrapper">
                                                    <img src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/ic-nav-menu-popular-2.png" className="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width={55} height={55} />
                                                    <h2 className="-title">เล่นล่าสุด</h2>
                                                </div>
                                                <nav className="nav-menu" id="navbarProvider">
                                                    <ul className="nav nav-pills">
                                                        {this.state.recentlist.map((listitem, i) => (
                                                            <li
                                                                key={i} id={listitem.gameid}
                                                                className="nav-item -game-slot-macro-container -square">
                                                                <a
                                                                    href={() => false}
                                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                                    className="nav-link js-account-approve-aware"
                                                                    rel="nofollow noopener"
                                                                >
                                                                    <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                                                        <div className="-inner-wrapper">
                                                                            <img

                                                                                src={listitem.image}
                                                                                width={250} height={250} className="-cover-img lazyload img-fluid" alt="Crown & Anchor | evo-play เว็บคาสิโนออนไลน์ สล็อตออนไลน์ รับเครดิตฟรี" />
                                                                            <div className="-overlay">
                                                                                <div className="-overlay-inner">
                                                                                    <div className="-wrapper-container">
                                                                                        <button href={"/listgameall/slot/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                                            <i className="fas fa-play"></i>
                                                                                            <span className="-text-btn">เข้าเล่น</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="-title">{listitem.description}</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                            : ""
                                        }
                                        <div className="-inner-heading-wrapper">
                                            <img src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-popular-casino.png" className="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width={55} height={55} />
                                            <h2 className="-title">คาสิโนออนไลน์ ยอดนิยม</h2>
                                        </div>
                                        <nav className="nav-menu" id="navbarProvider">
                                            <ul className="nav nav-pills">
                                                {this.state.categorylist.map((listitem, i) => (
                                                    <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                        <a href={"/listgameall/casino/" + listitem.partner}
                                                            className="nav-link js-account-approve-aware"
                                                            rel="nofollow noopener">
                                                            <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                                <div className="-inner-wrapper">
                                                                    <div className="x-game-badge-component -popular -big" data-animatable="fadeInUp" data-delay={400}>
                                                                        <span>Popular</span>
                                                                    </div>
                                                                    <picture>
                                                                        <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                    </picture>
                                                                    <div className="-overlay">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware"  >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="-title">{listitem.description}</div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                        <div className="-hot-slot-container">
                                            <div className="-inner-heading-wrapper">
                                                <img src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-popular-slot.png" className="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width={55} height={55} />
                                                <h2 className="-title">สล็อตออนไลน์ ยอดนิยม</h2>
                                            </div>
                                            <nav className="nav-menu" id="navbarProvider">
                                                <ul className="nav nav-pills">
                                                    {this.state.slotlist.map((listitem, i) => (
                                                        <li key={i} id={listitem.gameid} className="nav-item -game-slot-macro-container -square">
                                                            <a
                                                                href={"/listgameall/slot/" + listitem.partner}
                                                                className="nav-link js-account-approve-aware"
                                                                rel="nofollow noopener"
                                                            >
                                                                <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                                                    <div className="-inner-wrapper">
                                                                        <img src={listitem.image} width={250} height={250} className="-cover-img lazyload img-fluid" alt="Crown & Anchor | evo-play เว็บคาสิโนออนไลน์ สล็อตออนไลน์ รับเครดิตฟรี" />
                                                                        <div className="-overlay">
                                                                            <div className="-overlay-inner">
                                                                                <div className="-wrapper-container">
                                                                                    <button href={"/listgameall/slot/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                                        <i className="fas fa-play"></i>
                                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-title">{listitem.description}</div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Lowmenu />

            </section>
        );
    }
}

export default Main;
